<template>
  <vx-card title="">
    <vs-row class="core-skill-header">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="12" class="mb-lg-0 mb-3">
        <div class="vx-col w-full">
          <label class="text-sm opacity-75"><h3>{{coreSkillFilter.name}} Professional Development Resources</h3></label>
          <ul class="demo-alignment">
            <li>
              <vs-radio vs-name="rating" v-model="rating" vs-value="1" @change="onChangeRating($event)">
                1
              </vs-radio>
            </li>
            <li>
              <vs-radio vs-name="rating" v-model="rating" vs-value="2" @change="onChangeRating($event)">
                2
              </vs-radio>
            </li>
            <li>
              <vs-radio vs-name="rating" v-model="rating" vs-value="3" @change="onChangeRating($event)">
                3
              </vs-radio>
            </li>
            <li>
              <vs-radio vs-name="rating" v-model="rating" vs-value="4" @change="onChangeRating($event)">
                4
              </vs-radio>
            </li>
            <li>
              <vs-radio vs-name="rating" v-model="rating" vs-value="5" @change="onChangeRating($event)">
                5
              </vs-radio>
            </li>
          </ul>
        </div>
      </vs-col>

    </vs-row>

    <div class="op-block shadow-none p-0">
      <vs-row>
        <vs-col>
          <p class="h4">Resources</p>
        </vs-col>
        <vs-divider/>
      </vs-row>
      <template v-if="coreSkillDevelopments.length > 0">
        <vs-row v-if="coreSkillDevelopments.length > 0" :key="index"
                v-for="(item,index) in coreSkillDevelopments">
          <vs-col vs-justify="center" vs-align="center" vs-w="9"   vs-xs="12">
            <p class="h4">{{ item.title }}</p>
            <p>{{ item.description }}</p>
            <p class="h5 mb-2" style="display: inline-block">
<!--              <a v-if="item.link" :href="item.link"  target="_blank">
                <vs-button color="primary" type="filled" class="mt-3">Link</vs-button>
              </a>
              <a class="mt-5 ml-5" v-if="item.document" :href="item.document" target="_blank">
                <vs-button color="primary" type="filled" class="mt-3">View
                  Document
                </vs-button>
              </a>-->
              <a v-if="item.link"  @click="redirectToLink(item)" target="_blank">
                <vs-button color="primary" type="filled" class="mt-3">Link</vs-button>
              </a>
              <a class="mt-5 ml-5" v-if="item.document" @click="redirectToLink(item)"  target="_blank">
                <vs-button color="primary" type="filled" class="mt-3">View
                  Document
                </vs-button>
              </a>
              <!-- <a class="mt-5 ml-5" v-if="!item.document && !item.link" href="javascript::void()" @click="sendToTeacher(item)"  >
                <vs-button color="primary" type="filled" class="mt-3">Log
                  Document
                </vs-button>
              </a> -->
            </p>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="center" vs-xs:justify="start" vs-align="center" vs-lg="3" vs-xs="12">
          <vs-button
            type="border"
            icon-pack="feather"
            v-if="isLeadTeacher"
            icon="icon-plus"  @click="documentDetailPage(item._id)">Professional development log</vs-button>
            <vs-button
            type="border"
            icon-pack="feather"
            v-else
            icon="icon-plus"  @click="sendToTeacher(item._id)">Send to me</vs-button>
        </vs-col>
          <vs-divider/>
        </vs-row>
      </template>
      <template v-else>
        <vs-row>
          <vs-col vs-justify="center" vs-align="center" vs-w="9">
            <p>Sorry no data found.</p>
          </vs-col>
          <vs-divider/>
        </vs-row>
      </template>


    <vs-popup class="holamundo add-children-popup" title :active.sync="teacherPopup">
      <form class="p-0">
        <h2 class="h1 mb-6">Send To teacher</h2>
        <vx-card class="m-0 my-8">
          <vs-row>
            <vs-col vs-w="12">
                <div v-if="rooms.length > 0">
                <vs-select
                  label="Select Room"
                  :placeholder="this.roomName"
                  autocomplete
                  v-model="currentRoom"
                  class="mr-6"
                  disabled
                >
                  <vs-select-item
                    :key="index"
                    :disabled="index==0"
                    :value="room._id"
                    :text="room.name"
                    v-for="(room,index) in rooms"
                  />
                </vs-select>
                <span class="text-danger text-sm" v-show="roomError" >No Room selected.</span>
              </div>
              <p v-else>No rooms found</p>
            </vs-col>
          </vs-row>

          <vs-row class="mt-10">
            <vs-col vs-w="12">
              <div v-if="teacherFullName">
                <ul v-if="teachers.length > 0">
                  <li>
                    <div class="round--" style="display: flex; align-items: center">
                      <input
                        :id="'checkbox-a'"
                        type="checkbox"
                        @click="toggleAllTeachers($event)"
                      />
                      <span style="margin-left: 23px; font-size: 16px; line-height: 22px;">Selected Teacher</span>
                    </div>
                  </li>
                  <li v-for="(teacher, key) in teachers" :key="key">
                    <div class="round--" style="display: flex; align-items: center">
                      <input
                        :id="'checkbox-'+key"
                        name="selectedTeacher"
                        type="checkbox"
                        v-model="selectedTeachers"
                        :value="teacher._id"
                      />
                      <vs-avatar size="46px" :src="teacher.profileImage" style="margin-left: 22px;" />
                      <span
                        style="margin-left: 6px; font-size: 16px; line-height: 22px;"
                      >{{ teacher.fullName }}</span>
                    </div>
                  </li>
                  <span class="text-danger text-sm" v-show="teacherError" >No Teacher selected.</span>
                </ul>
                <p v-else>No teachers in this room.</p>
              </div>
            </vs-col>
          </vs-row>

//     <span class="text-danger text-sm" >errors</span>-
        </vx-card>
        <div class="flex justify-between mt-5">
          <vs-button @click="cancelSendTeacher" color="primary" type="filled">Cancel</vs-button>
          <vs-button @click="sendToTeacher" color="primary" type="filled">Send</vs-button>
        </div>
      </form>
    </vs-popup>


    </div>
  </vx-card>
</template>

<script>
import vSelect from 'vue-select'
import {mapActions} from "vuex";
import _ from "lodash";

export default {
  data() {
    return {
      rating: "1",
      coreSkills: {},
      coreSkillsOptions: [],
      coreSkillFilter: {},

      coreSkillDevelopments: {},
      coreSkillDevelopmentID: null,

      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},

      currentResource: {},
      roomName:"",
      rooms:[],
      teachers:[],
      selectedTeachers:[],
      totalTeacherIds:[],
      teacherFullName: '',
      ownId: '',
      teacherPopup: false,
      isLeadTeacher: false,
      currentRoom:null,
      teacherError:null,
      roomError:null
    }
  },
  watch: {
    coreSkillFilter(obj) {
      this.getCoreSkillDevelopments();
    },
  },
  methods: {
    ...mapActions("coreSkill", [
      "getCoreSkillDevelopment",
      "fetchCoreSkills",
      "setRating",
      "logProfessionalDevelopmentForTeachers",
      "getCoreskillById"
    ]),
    ...mapActions("room", [
      "getTeachersByRoomId",
    ]),

    toggleAllTeachers(event) {
      if (event.target.checked) {
        this.selectedTeachers =  this.totalTeacherIds;
      }else{
        this.selectedTeachers = [];
      }
    },
    async sendToTeacher(item) {
      let resourceId;
      if (!this.isLeadTeacher) {
        this.selectedTeachers = [this.ownId]
        resourceId = item
      } else {
        resourceId = this.resourceId
      }
      const log = {
        coreSkillId: this.$route.params.coreSkillId,
        resourceId,
        teacherIds: this.selectedTeachers,
        rating: this.rating,
      }

      let self = this;

      this.$vs.loading()
      this.logProfessionalDevelopmentForTeachers(log)
        .then((res) => {
          self.showMessage("Success", "Self assessment successfully sent.", "success");
          self.teacherPopup = false;
          self.$vs.loading.close()
        })
        .catch(err => {
          self.$vs.loading.close()
        })
    },

    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: color
      })
    },

    async redirectToLink(item){


          if(item.link){
            window.location.href = item.link;
          }else{
            window.location.href = item.document;
          }


      // const log = {
      //   coreSkillId: this.$route.params.coreSkillId,
      //   resourceId: item._id,
      //   teacherId: this.$store.state.AppActiveUser._id,
      //   rating: this.rating,
      // }
      // this.logProfessionalDevelopmentResource(log)
      //   .then((res) => {
      //     this.$vs.loading.close()
      //     if(item.link){
      //       window.location.href = item.link;
      //     }else{
      //       window.location.href = item.document;
      //     }
      //   })
      //   .catch(err => {
      //     this.$vs.loading.close()
      //   })

    },
    onChangeRating(event) {
      this.setRating(this.rating)
      this.getCoreSkillDevelopments();
    },

    getCoreSkillDevelopments() {
      let filter = {
        coreSkill: this.coreSkillFilter.value,
        rating: this.rating,
      }

      this.$vs.loading()
      this.getCoreSkillDevelopment(filter)
        .then((res) => {
          this.$vs.loading.close()
          this.coreSkillDevelopments = this.$store.state.coreSkill.coreSkillDevelopments;
        })
        .catch(err => {
          this.$vs.loading.close()
          console.error(err)
        })
    },
    documentDetailPage(fastCheckId){

    this.resourceId = fastCheckId;

    this.teacherPopup = true;
    },
    cancelSendTeacher(){
    this.teacherPopup = false;
    }
  },
  async mounted() {
    if (this.$store.state.AppActiveUser.role == 'leadTeacher') {
      this.isLeadTeacher = true;      
      this.getTeachersByRoomId(this.$store.state.AppActiveUser.room._id)
          .then(async res => {
            this.teachers = res.data.data;
            this.totalTeacherIds = res.data.data.map(item => {
              return item._id;
            });
          })
          .catch(err => {
            console.log(err);
          });
    }
  },
  components: {
    vSelect
  },
  created() {
    this.rating = this.$route.params.rating || 1;
    this.roomName = this.$store.state.AppActiveUser.room.name
    this.rooms.push({
      name: this.$store.state.AppActiveUser.room.name,
      _id: this.$store.state.AppActiveUser.room._id
    })
    this.teachers.push({
      _id: this.$store.state.AppActiveUser._id,
      fullName: this.$store.state.AppActiveUser.fullName
    })
    this.ownId = this.$store.state.AppActiveUser._id,
    this.teacherFullName = this.$store.state.AppActiveUser.fullName
    this.$vs.loading()
    this.fetchCoreSkills()
      .then((res) => {
        this.$vs.loading.close()
        this.coreSkillsOptions = this.$store.state.coreSkill.coreSkillsOptions;
        const coreSkillIndex = this.coreSkillsOptions.findIndex((u) => u.value == this.$route.params.coreSkillId)
        this.coreSkillFilter = this.coreSkillsOptions[coreSkillIndex];
        console.log(this.coreSkillFilter);
      })
      .catch(err => {
        this.$vs.loading.close()
        console.error(err)
      })

      // this.getCoreskillById(this.$route.params.coreSkillId)
      // .then((res) => {
      //   this.coreSkill = res.data.data;
      //   console.log(res,"resres")
      // })
      // .catch(err => {
      //   this.$vs.loading.close()
      //   console.error(err)
      // })


    //previous
    /*const log = {
      coreSkillId: this.$route.params.coreSkillId,
      teacherId: this.$store.state.AppActiveUser._id,
      rating: this.$route.params.rating,
    }
    this.logProfessionalDevelopment(log)
      .then((res) => {
        this.$vs.loading.close()
      })
      .catch(err => {
        this.$vs.loading.close()
      })*/


  },
}
</script>
